<template>
  <ul class="icon-container">
    <li class="share">
      <img src="@/assets/svg/svg/icon-01.svg" alt="" />
      <ul class="share_box">
        <div class="sanjiao"></div>
        <li @click.stop="weChat" class="weChat">
          <img src="@/assets/img/details/weChat.png" alt="" />
          <div>微信</div>
          <div
            :id="`WXqrcode${curcer}`"
            :ref="`WXqrcode${curcer}`"
            class="ma"
          ></div>
        </li>
        <li @click.stop="weibo">
          <img src="@/assets/img/details/microblog.png" alt="" />
          <div>新浪微博</div>
        </li>
        <li @click.stop="qq" class="qq">
          <img src="@/assets/img/details/QQ.png" alt="" />
          <div>QQ</div>
          <div
            :id="`QQqrcode${curcer}`"
            :ref="`QQqrcode${curcer}`"
            class="ma"
          ></div>
        </li>
        <li @click.stop="space">
          <img src="@/assets/img/details/qqSpace.png" alt="" />
          <div>QQ空间</div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    every: {
      type: Object,
      required: true,
    },
    curcer: {
      type: String,
    },
  },
  created() {
    this.qq();
    this.weChat();
  },
  data() {
    return {
      WXurl: "",
      QQurl: "",
    };
  },
  methods: {
    getTitle() {
      let title = ""
      switch (this.every.articleType) {
        case 0:
        case 5:
            title = this.every.data.title
          break;
        case 1:
            title = this.every.data.patentName
          break;
        case 2:
            title = this.every.data.monographName
          break;
        case 3:
            title = this.every.data.reportName
          break;
        case 4:
            title = this.every.data.standardName
          break;
      }
      return title
    },
    getUrl() {
      let url = "";
      //  articleType 
      //  0 会议/期刊论文/其他成果 1 专利 2 专著 3 科技报告 4 标准 5 资讯
      if (this.every.articleType == 0 && this.every.data.type == 0) {
        url =
          this.$global.url3 +
          `/paperpreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      } else if (this.every.articleType == 0 && this.every.data.type == 1) {
        url =
          this.$global.url3 +
          `/conferencepaperspreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      } else if (this.every.articleType == 0 && this.every.data.type == 2) {
        url =
          this.$global.url3 +
          `/conferencepaperspreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      } else if (this.every.articleType == 0 && this.every.data.type == 4) {
        url =
          this.$global.url3 +
          `/conferenceotherpreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      } else if (this.every.articleType == 1) {
        url =
          this.$global.url3 +
          `/patentpreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      } else if (this.every.articleType == 2) {
        url =
          this.$global.url3 +
          `/monographpreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      } else if (this.every.articleType == 3) {
        url =
          this.$global.url3 +
          `/reportpreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      } else if (this.every.articleType == 4) {
        url =
          this.$global.url3 +
          `/standardpreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      }else if (this.every.articleType == 5) {
        url =
          this.$global.url3 +
          `/informationpreview?articleType=${this.every.articleType}&articleId=${this.$route.query.articleId}`;
      }
      return url;
    },
    weChat() {
      if (this.WXurl !== "") return;
      this.WXurl = this.getUrl();
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.WXcrateQrcode();
      });
    },
    WXcrateQrcode() {
      this.qr = new QRCode("WXqrcode" + this.curcer, {
        width: 150,
        height: 150, // 高度
        text: this.WXurl, // 二维码内容
      });
    },
    QQcrateQrcode() {
      this.qr = new QRCode("QQqrcode" + this.curcer, {
        width: 150,
        height: 150, // 高度
        text: this.QQurl, // 二维码内容
      });
    },
    qq() {
      if (this.QQurl !== "") return;
      this.QQurl = this.getUrl();
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.QQcrateQrcode();
      });
    },
    weibo() {
      let url = this.getUrl();
      let title = this.getTitle()
      let srcUrl = "";
      this.every.data.cover
        ? (srcUrl = `pic=${this.$global.imgUrl + this.every.data.cover}`)
        : (srcUrl = "");
      window.open(
        `https://service.weibo.com/share/share.php?${srcUrl}url=${url}&&title=${title}`
      );
    },
    space() {
      let url = this.getUrl();
      let title = this.getTitle()
      let srcUrl = "";
      this.every.data.cover
        ? (srcUrl = `pics=${this.$global.imgUrl + this.every.data.cover}`)
        : (srcUrl = "");
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?${srcUrl}url=${url}&&title=${title}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  margin-top: 16px;
  display: flex;
  li {
    width: 63px;
    height: 24px;
    margin-right: 13px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    .imgBtn {
      width: 63px;
      height: 24px;
      margin: 0;
      padding: 0;
      border: none;
      &:focus{
      box-shadow: none!important;
      }
      img {
        width: 63px;
        height: 24px;
      }
    }
  }
  .read {
    width: 95px;
    height: 24px;
    .imgBtn {
      width: 95px;
      height: 24px;
      img {
        width: 95px;
        height: 24px;
      }
    }
  }
  .share:hover {
    .share_box {
      display: block;
    }
  }
  .share {
    position: relative;
    .share_box {
      cursor: pointer;
      display: none;
      right: -133px;
      top: -130px;
      position: absolute;
      width: 133px;
      height: 167px;
      background: #ffffff;
      box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
      border-radius: 10px;
      .weChat:hover {
        .ma {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .weChat {
        position: relative;
        .ma {
          display: none;
          left: 123px !important;
          position: absolute !important;
          width: 200px !important;
          top: -10px !important;
          height: 167px !important;
          background: white !important;
          box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
          border-radius: 10px;
        }
      }
      .qq:hover {
        .ma {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .qq {
        position: relative;
        .ma {
          display: none;
          left: 123px !important;
          position: absolute !important;
          width: 200px !important;
          top: -90px !important;
          height: 167px !important;
          background: white !important;
          box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
          border-radius: 10px;
        }
      }
      li {
        margin-top: 10px;
        margin-bottom: 0px;
        display: flex;
        flex-wrap: nowrap;
        //background: pink;
        justify-content: center;
        align-items: center;
        width: 133px;
        height: 30px;

        div {
          width: 60px;
          //background: yellow;
          margin-left: 10px;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
      .sanjiao {
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-right: 6px solid #fff;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        left: -6px;
        border-bottom: 3px solid transparent;

        //background: pink;
      }
    }
  }
}
</style>
