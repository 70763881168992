<template>
  <div class="information-detail-container">
    <p class="invalid">该页面为预览效果，短期内将会失效</p>
    <div class="head">
      <div class="top">
        <span class="type">[资讯]</span>
        <span class="title">{{ detailsData.title }}</span>
      </div>
      <IconButton1
        v-if="detailsData.id"
        :every="tempData"
        :curcer="detailsData.id"
      ></IconButton1>
      <p><span>发布时间：</span>{{ detailsData.createTime | dayFormate }}</p>
      <p>
        <span>来&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;源：</span
        >{{ detailsData.source }}
      </p>
    </div>
    <div class="content" v-html="detailsData.content"></div>
  </div>
</template>

<script>
import IconButton1 from "../components/IconButton1.vue";
import details from "@/mixins/details.js";
export default {
  components: {
    IconButton1,
  },
  mixins: [details],
  data() {
    return {
      detailsData: {},
    };
  },
  created() {
    this.getDetails({
      articleId: this.$route.query.articleId,
    });
  },
};
</script>

<style lang="scss" scoped>
.information-detail-container {
  .invalid {
    margin: 0 auto 50px;
    text-align: center;
    width: 396px;
    height: 45px;
    line-height: 45px;
    background: #f9f9f9;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .icon-container {
    margin-top: 16px;
    display: flex;
    li {
      width: 63px;
      height: 24px;
      margin-right: 13px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .share:hover {
      .share_box {
        display: block;
      }
    }
    .share {
      position: relative;
      .share_box {
        cursor: pointer;
        display: none;
        right: -133px;
        top: -130px;
        position: absolute;
        width: 133px;
        height: 167px;
        background: #ffffff;
        box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
        border-radius: 10px;
        .weChat:hover {
          .ma {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .weChat {
          position: relative;
          .ma {
            display: none;
            left: 123px !important;
            position: absolute !important;
            width: 200px !important;
            top: -10px !important;
            height: 167px !important;
            background: white !important;
            box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
            border-radius: 10px;
          }
        }
        .qq:hover {
          .ma {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .qq {
          position: relative;
          .ma {
            display: none;
            left: 123px !important;
            position: absolute !important;
            width: 200px !important;
            top: -90px !important;
            height: 167px !important;
            background: white !important;
            box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
            border-radius: 10px;
          }
        }
        li {
          margin-top: 10px;
          margin-bottom: 0px;
          display: flex;
          flex-wrap: nowrap;
          //background: pink;
          justify-content: center;
          align-items: center;
          width: 133px;
          height: 30px;

          div {
            width: 60px;
            //background: yellow;
            margin-left: 10px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        .sanjiao {
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-right: 6px solid #fff;
          position: absolute;
          top: 50%;
          margin-top: -3px;
          left: -6px;
          border-bottom: 3px solid transparent;

          //background: pink;
        }
      }
    }
  }
  .head {
    border-bottom: 1px dashed #d6d6d6;
    .top {
      display: flex;
      align-items: center;
      .type {
        font-size: 30px;
        color: #999999;
      }
      .title {
        font-size: 30px;
        color: #333333;
        margin: 0 5px;
      }
      .column {
        display: inline-block;
        width: 76px;
        height: 30px;
        background: #00a4ff;
        border: 1px solid #00a4ff;
        border-radius: 11px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }
    p {
      margin: 15px 0;
      span {
        font-size: 14px;
        color: #999999;
        margin-top: 14px;
      }
    }
    .auther {
      font-size: 14px;
      color: #999999;
      margin-top: 14px;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
    .unit {
      margin-top: 14px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .content {
    padding-top: 40px;
    ::v-deep table {
      border-collapse:collapse;
      border-spacing:0;
      th {
        width: 190px;
        height: 35px;
        min-height: 30px;
        border: 1px solid #ccc;
        padding: 3px 5px;
        background-color: #f1f1f1;
      }
      td {
        width: 190px;
        height: 35px;
        min-height: 30px;
        border: 1px solid #ccc;
        padding: 3px 5px;
      }
    }
  }
}
</style>
